import {
  createContext,
  useState,
  useEffect,
  useMemo,
  Context,
} from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import UserFactory, { CurrentUserSimpleResponse } from 'SharedDirectory/factories/user_factory';
import { dig } from 'SharedDirectory/utils';
import { CurrentUserContext } from '@local_types';
/**
 * @summary custom hook into current user value provided by UserContext
 */

type Report = { id: number; name: string };
type GeminiUser = CurrentUserContext['currentUser'];
type RailsUser = CurrentUserSimpleResponse['data'];
export type Permissions = {
  insideSales: boolean;
  outsideSales: boolean;
  isDirector?: boolean;
  isSalesCoach: boolean;
  isIssDirector: boolean;
  isOssDirector: boolean;
  isCEO: boolean;
  isCTO: boolean;
  isCFO: boolean;
  canViewOssDashboard: boolean;
  canEditOssDashboard: boolean;
  canViewCloserStatus: boolean;
  canEditIssCallLogs: boolean;
  canViewUserManagement: boolean;
  canEditSnapshot: boolean;
  canAddNewRole: boolean;
  isAdmin: boolean;
  accounts: {
    canShowRRSavings: boolean;
    canUpdateFinanceFields: boolean;
    canSelectFullPmtTermOpt: boolean;
    canEnableMirrorInvoicing: boolean;
    canDisableMirrorInvoicing: boolean;
  };
  opportunities: {
    canModify: boolean;
    canCreate: boolean;
    isSalesManagement: boolean;
    canModifySplits: boolean;
    allowedStages: string[];
    isAdmin: boolean;
  };
  bulk: {
    bills: boolean;
    workflows: boolean;
    updateWorkflows: boolean;
    uploads: boolean;
    adjustments: boolean;
    accounts: boolean;
    invoiceHiddenItems: boolean;
  };
  engagements: {
    canCreateEngagement: boolean;
    canEditEngagement: boolean;
    canViewEngagement: boolean;
    canDeleteEngagement: boolean;
    canModifyOldCost: boolean;
    canCreatePostCloseEgmt: boolean;
    canUploadPostCloseMma: boolean;
  };
  adjustments: {
    canApprove: boolean;
    canUpdateAdjustmentDate: boolean;
    allowedChargeTypes: string[];
    allowedCreditTypes: string[];
  };
  users: {
    canUpdateUserInfo: boolean;
  };
  locations: {
    canEditManualNotKey: boolean;
    canEditForcedClosedWon: boolean;
    canEditIncidentChargeDate: boolean;
    canEditCameraFriendly: boolean;
  };
  portfolio: {
    canEditManualKey: boolean;
  };
  reimbursementRates: {
    canModify: boolean;
  };
  groups: {
    accountability: {
      canModify: boolean;
    };
  };
  haulerLiquidation: {
    canEditHaulerLiquidation: boolean;
    canDeleteHaulerLiquidation: boolean;
    canReturnHaulerLiquidation: boolean;
  };
  term: {
    canManageTerm: boolean;
  };
  fleetHaul: {
    publishRoute: boolean;
  };
  canEditLocationFinanceFields: boolean;
  canEditLocationAlwaysPayHauler: boolean;
  canSendSignatureRequest: boolean;
  canEditStreamType: boolean;
  canViewOppLocParing: boolean;
  canEditFhFormNotEligible: boolean;
  canShowLocationsUnfiltered: boolean;
  hasInvoicingNotesPermission: boolean;
  canEnterCrossPortfolioBills: boolean;
};
export type CurrentUser = {
  id: number;
  departmentId: number | null;
  firstName: string;
  lastName: string;
  name: string;
  marketId: number | null;
  issDailyLogCaptured: string | null;
  permissions: Permissions;
  reports: Report[];
  parentUser?: {
    id: number;
    name: string;
  };
  imageUrl?: string;
};
// ideally this would export from UserContext
export type UserContextValue = {
  currentUser: CurrentUser;
  status: string;
  refetch: () => Promise<any>;
};

type UserProviderProps = React.PropsWithChildren<{}>;

interface UserContextType extends Context<UserContextValue> {
  UserProvider: React.ComponentType<UserProviderProps>;
}

// @ts-ignore
const UserContext: UserContextType = createContext();

export const userQuery = gql`
  query CurrentUserContext {
    currentUser {
      id
      departmentId
      firstName
      lastName
      name
      marketId
      active
      imageUrl
      issDailyLogsByUserId(
        filter: { activityDate: { equalTo: "NOW" } }
        first: 1
      ) {
        nodes {
          id
          activityType
          activityDate
        }
      }
      userPermissionsByUserId {
        nodes {
          permissionByPermissionId {
            id
            name
          }
        }
      }
      userSubordinatesByUserId {
        nodes {
          id
          userBySubordinateId {
            id
            name
            active
          }
        }
      }
      userRolesByUserId {
        nodes {
          id
          roleByRoleId {
            id
            name
            admin
            allRolePermissionsByRoleId {
              nodes {
                path
                permissionByPermissionId {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
const useRailsCurrentUser = () => {
  const [railsCurrentUser, setRailsCurrentUser] = useState<Partial<RailsUser>>({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    UserFactory.getCurrentUserSimple()
      .then((data) => {
        setRailsCurrentUser(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  return { loading, currentUser: railsCurrentUser };
};

const UserProvider = (props: UserProviderProps) => {
  const { children } = props;
  const { data, loading, error, refetch } = useQuery<CurrentUserContext>(userQuery);
  const { currentUser: railsUser } = useRailsCurrentUser();
  const status = useMemo(() => {
    if (loading) return 'loading';
    if (error) return 'error';
    return 'success';
  }, [loading, error]);
  const currentUser = useMemo(() => {
    if (!data) return {};
    const { currentUser: geminiUser } = data;
    const {
      id,
      departmentId,
      firstName,
      lastName,
      name,
      marketId,
      active,
      imageUrl,
      userSubordinatesByUserId: { nodes: reports } = { nodes: [] } as GeminiUser['userSubordinatesByUserId'],
      userRolesByUserId: { nodes: userRoles } = { nodes: [] } as GeminiUser['userRolesByUserId'],
      userPermissionsByUserId: { nodes: uPermissions } = { nodes: [] } as GeminiUser['userPermissionsByUserId'],
      issDailyLogsByUserId: { nodes: issLogs },
    } = geminiUser || {} as GeminiUser;
    const allRoles = userRoles.map((ur) => ur.roleByRoleId);
    // This Hash is {[permissionName]:true} for all roles for this user
    const userPHash = uPermissions.reduce((a, c) => {
      const {
        permissionByPermissionId: { name: pName },
      } = c;
      return { ...a, [pName]: true };
    }, {} as { [key: string]: boolean });
    const pHash = allRoles.reduce((allP, role) => {
      const {
        allRolePermissionsByRoleId: { nodes },
      } = role;
      const rolePermissionHash = nodes.reduce((a, c) => {
        const {
          permissionByPermissionId: { name: pName },
        } = c;
        return { ...a, [pName]: true };
      }, {});
      return { ...allP, ...rolePermissionHash };
    }, userPHash);
    const isAdmin = !!allRoles.find((r) => r.admin);
    const isSalesManagement = !!allRoles.find((r) =>
      [
        'Director of Outside Sales',
        'Director of Inside Sales',
        'Regional Manager',
        'Pricing Development Specialist',
        'Project Manager, Enterprise',
      ].includes(r.name)
    );
    return {
      id,
      departmentId,
      firstName,
      lastName,
      name,
      marketId,
      active,
      imageUrl,
      parentUser: railsUser ? railsUser.parentUser : null,
      reports: reports
        .filter((r) => {
          const { userBySubordinateId: u } = r;
          return u.active;
        })
        .map((r) => {
          const {
            userBySubordinateId: { id: subId, name: subName },
          } = r;
          return { id: subId, name: subName };
        }),
      permissions: {
        insideSales: departmentId === 8,
        outsideSales: departmentId === 7,
        canShowLocationsUnfiltered: !!pHash.can_show_locations_unfiltered,
        isIssDirector: !!allRoles.find(
          (r) => r.name === 'Director of Inside Sales'
        ),
        isOssDirector: !!allRoles.find(
          (r) => r.name === 'Director of Outside Sales'
        ),
        isSalesCoach: !!allRoles.find((r) => r.name === 'Sales Coach'),
        isOssSeniorDirector: !!allRoles.find(
          (r) => r.name === 'Senior Director of Sales, Enterprise'
        ),
        isPricingDevSpecialist: !!allRoles.find(
          (r) => r.name === 'Pricing Development Specialist'
        ),
        isCEO: !!allRoles.find((r) => r.name === 'CEO'),
        isCTO: !!allRoles.find((r) => r.name === 'CTO'),
        isCFO: !!allRoles.find((r) => r.name === 'CFO'),
        canViewOssDashboard: !!pHash.can_show_oss_mrr_goals,
        canEditOssDashboard: !!pHash.can_update_oss_mrr_goals,
        canViewCloserStatus: !!pHash.can_show_oss_closer_status,
        canEditIssCallLogs: !!pHash.can_update_iss_daily_logs,
        canViewUserManagement: true,
        canEditSnapshot: !!pHash.can_update_proposal_snapshot,
        canAddNewRole: !!pHash.can_create_roles,
        isAdmin,
        accounts: {
          canShowRRSavings: !!pHash.can_show_rrsavings_field,
          canUpdateFinanceFields: !!pHash.can_update_accounts_finance_fields,
          canSelectFullPmtTermOpt:
            !!pHash.can_show_accounts_full_payment_terms_option,
          canEnableMirrorInvoicing: !!pHash.can_enable_mirror_invoicing,
          canDisableMirrorInvoicing: !!pHash.can_disable_mirror_invoicing,
          canUpdateInvoiceCbsInArrears:
            !!pHash.can_update_invoice_cbs_in_arrears,
          canUpdateCoyoteInvoiceDocumentSettings: !!pHash.can_update_coyote_invoice_document_setting,
        },
        opportunities: {
          isSalesManagement,
          canModify: !!pHash.can_update_opportunities,
          canCreate: !!pHash.can_create_opportunities,
          canModifySplits: !!pHash.can_update_opportunities_splits,
          allowedStages:
            dig(['permissions', 'opportunities', 'allowedStages'], railsUser) ||
            [],
          isAdmin,
        },
        bulk: {
          bills: !!pHash.can_create_bulk_bills_upload,
          workflows: !!pHash.can_create_bulk_workflow_instance,
          updateWorkflows: !!pHash.can_update_bulk_workflow_instance,
          uploads: !!pHash.can_update_bulk_batch_upload,
          adjustments: !!pHash.can_create_bulk_adjustments,
          accounts: !!pHash.can_update_bulk_accounts,
          invoiceHiddenItems: !!pHash.can_create_bulk_invoice_hidden_item,
        },
        engagements: {
          canCreateEngagement: !!pHash.can_create_engagements,
          canEditEngagement: !!pHash.can_update_engagements,
          canViewEngagement: true,
          canDeleteEngagement: !!pHash.can_destroy_engagements,
          canModifyOldCost: !!pHash.can_update_engagements_old_cost,
          canCreatePostCloseEgmt:
            !!pHash.can_create_engagements_post_close_egmt,
          canUploadPostCloseMma: !!pHash.can_update_engagements_post_close_mma,
        },
        adjustments: {
          canApprove: !!pHash.can_update_adjustment_queues_approve,
          canUpdateAdjustmentDate:
            !!pHash.can_update_adjustments_queues_adjustment_date,
          // These needs some more thought
          allowedChargeTypes:
            dig(
              ['permissions', 'adjustments', 'allowedChargeTypes'],
              railsUser
            ) || [],
          allowedCreditTypes:
            dig(
              ['permissions', 'adjustments', 'allowedCreditTypes'],
              railsUser
            ) || [],
        },
        users: {
          canUpdateUserInfo: !!pHash.can_update_users,
        },
        locations: {
          canEditManualNotKey: !!pHash.can_update_locations_manual_not_key,
          canEditForcedClosedWon:
            !!pHash.can_update_locations_forced_closed_won,
          canEditCameraFriendly: !!pHash.can_update_loc_camera_friendly,
          canEditIncidentChargeDate: !!pHash.can_update_incident_charge_date
        },
        portfolio: {
          canEditManualKey: !!pHash.can_update_portfolios_manual_key,
        },
        reimbursementRates: {
          canModify: !!pHash.can_update_reimbursement_rates,
        },
        groups: {
          accountability: {
            canModify: !!pHash.can_update_groups_accountability_groups,
          },
          opsAccountability: {
            canModify: !!pHash.can_update_groups_ops_accountability_group,
            canModifyStrategySpecialist:
              !!pHash.can_update_groups_ops_accountability_group_strategy_specialist,
            canModifyLeader:
              !!pHash.can_update_groups_ops_accountability_group_leader,
          },
        },
        haulerLiquidation: {
          canEditHaulerLiquidation: !!pHash.can_update_hauler_liquidations,
          canDeleteHaulerLiquidation: !!pHash.can_destroy_hauler_liquidations,
          canReturnHaulerLiquidation: !!pHash.can_return_hauler_liquidations,
        },
        term: {
          canManageTerm: !!pHash.can_update_terms_manage,
        },
        fleetHaul: {
          publishRoute: pHash.can_update_fleet_haul_routes_utility,
        },
        canEditLocationFinanceFields:
          !!pHash.can_update_locations_location_finance_fields,
        canEditLocationAlwaysPayHauler:
          !!pHash.can_update_locations_rr_always_pay_hauler,
        canSendSignatureRequest: !!pHash.can_update_signature_request,
        canEditStreamType: !!pHash.can_update_stream_types,
        canViewOppLocParing: !!pHash.can_show_opportunities_location_pairing,
        canEditFhFormNotEligible:
          !!pHash.can_update_fleet_haul_integration_forms_not_eligible_permit,
        hasInvoicingNotesPermission:
          !!pHash.can_update_accounts_invoicing_notes_permission,
        collections: {
          canBatchSendFirstNotice:
            !!pHash.can_create_collections_first_notice_in_batch,
        },
        fleetHaulComments: {
          canCreate: !!pHash.can_create_fleet_haul_comments,
          canUpdate: !!pHash.can_update_fleet_haul_comments,
        },
        canEnterCrossPortfolioBills:
            !!pHash.can_enter_cross_portfolio_bills,
      },
      issDailyLogCaptured: issLogs.length > 0 ? issLogs[0].activityDate : null,
    };
  }, [data, railsUser]);

  return (
    <UserContext.Provider value={{
      currentUser: currentUser as CurrentUser,
      status,
      refetch
    }}>
      {children}
    </UserContext.Provider>
  );
};

UserContext.UserProvider = UserProvider;
export default UserContext;
